import Http from "../../../utils/http";
import { accessType } from '../../../entities/AccessType/index';

class TopDownBoardService extends Http {
  public constructor() {
    super();
  }

  public getTableBoard = (companyId: number, businessType: number, level2: number) =>
    this.instance.get(
      `/company/${companyId}/pgv/topdown-board?business_type_id=${businessType}&level2_id=${level2}`
    );

    public getTableRegion = (companyId: number, businessType: number, level2: number) =>
      this.instance.get(
        `/company/${companyId}/pgv/topdown-region?business_type_id=${businessType}&level2_id=${level2}`
      );

      public getTableProductPotential = (companyId: number, productType: number,accessType:number, level3: number, level1:number) =>
        this.instance.get(
          `/company/${companyId}/pgv/product-potential?product=${productType}&accessType=${accessType}&level3_id=${level3}&level1_id=${level1}`
        );

        public getTableProductPotentialMediaRegional = (companyId: number, productType: number, level2: number) =>
          this.instance.get(
            `/company/${companyId}/pgv/product-media-regional?product=${productType}&level2_id=${level2}`
          );
    
  



        public getTableSetupProductPotential = (companyId: number) =>
          this.instance.get(
            `/company/${companyId}/pgv/product-potential/setup`
          );
    
  
          public saveTablelevel3ProductPotential = (companyId: number, body: object) =>
            this.instance.post(`/company/${companyId}/pgv/product-potential/setup`, body);


  public saveTableBoard = (companyId: number, body: object) =>
    this.instance.post(`/company/${companyId}/pgv/topdown-board`, body);

  public saveTableRegion = (companyId: number, body: object) =>
    this.instance.post(`/company/${companyId}/pgv/topdown-region`, body);

  public saveTableProductPotential = (companyId: number, body: object) =>
    this.instance.post(`/company/${companyId}/pgv/product-potential`, body);

  public putTableBoardStatus = (id: number, body: object) =>
    this.instance.put(`/company/${id}/pgv/topdown-Board/status`, body);

  public getTableBoardStatus = (id: number) =>
    this.instance.get(`/company/${id}/pgv/topdown-Board/status`);

  public getTableBoardVerify = (id: number, businessType: number) =>
    this.instance.get(
      `/company/${id}/pgv/topdown-Board/verify?business_type_id=${businessType}`
    );

  public searchTableBoard = (
    companyId: number,
    businessType: any,
    level2: any,
    search: string
  ) =>
    this.instance.get(
      `/company/${companyId}/pgv/topdown-board?business_type_id=${businessType}&level2_id=${level2}&filter_text=${search}`
    );

    public searchTableRegion = (
      companyId: number,
      businessType: any,
      level2: any,
      search: string
    ) =>
      this.instance.get(
        `/company/${companyId}/pgv/topdown-region?business_type_id=${businessType}&level2_id=${level2}&filter_text=${search}`
      );

      public searchTableProductPotential = (
        companyId: number,
        productType: any,
        accessType:any,
        level3: any,
        level1: any,
        search: string
      ) =>
        this.instance.get(
          `/company/${companyId}/pgv/product-potential?product=${productType}&accessType=${accessType}&level3_id=${level3}&level1_id=${level1}&filter_text=${search}`
        );
}

export default new TopDownBoardService();

import { useNavigate } from "react-router-dom";
import {
  Breadcrumb as BreadcrumbAntd,
  Button,
  ButtonProps,
  message,
  Modal,
  Spin,
  Typography,
} from "antd";
import BottomUpService from "../../services/SalesPlanning/BottomUp";
import leftIcon from "../../assets/left.svg";
import SalesPlanningService from "../../services/SalesPlanning";
import { Container, Header, Actions, Content, LeftIcon } from "./styles";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";
import TopDownCompanyService from "../../services/SalesPlanning/TopDownCompany";
import TopDownBoardService from "../../services/SalesPlanning/TopDownBoard";
import TopDownProductService from "../../services/SalesPlanning/TopDownProduct";
import WalletService from "../../services/Global/Wallet";
import CompanyCommercialStructureService from "../../services/Company/CommercialStructure";
import CostomerMappingService from "../../services/Global/CustomerMapping";
import SalesForecastService from "../../services/Global/SalesForecast";
import ActionPlanService from "../../services/Pgc/ActionPlan";
import { NoticeType } from "antd/lib/message/interface";
interface BreadcrumbProps {
  children: React.ReactNode;
  background?: boolean;
  separator?: string;
  path: string;
  child?: string[];
  disabled?: boolean;
  actions?: ButtonProps[];
}
export function Breadcrumb({
  path,
  background,
  child,
  actions,
  children,
  disabled,
}: BreadcrumbProps) {
  const [messageApi, contextHolder] = message.useMessage();
  const [ids, setIds] = useState<any[]>([]);
  const [names, setNames] = useState<any[]>([]);
  const navigate = useNavigate();
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [visible, setVisible] = useState(false);
  const [statusBottomUp, setStatusBottomUp] = useState<any>([]);
  const [status, setStatus] = useState<any>("");
  const [external_name, setExternal_name] = useState<any>("");
  const [verifyBoards, setVerifyBoard] = useState<boolean>(false);
  const [statusv1, setStatusv1] = useState<boolean>(false);
  const [statusv2, setStatusv2] = useState<boolean>(false);
  const [mensagem1, setMensagem1] = useState<string>(
    "Checando se o TopDown Empresa está fechado..."
  );
  const [mensagem2, setMensagem2] = useState<string>(
    "Checando se os valores do tipo de negócio estão de acordo com o planejado"
  );
  const ShowMSG = (type: NoticeType, test: string) => {
    messageApi.open({
      type,
      content: test,
    });
  };
  useEffect(() => {
    actions?.map((_, item) => {
      setIds([_.className]);
      setNames([_.rootClassName]);
    });
  }, [actions]);
  actions?.map((_, item) => {
    if (ids?.length < 1) {
      setIds([_.className]);
      setNames([_.rootClassName]);
      console.log(actions);
    }

    if (status === "") {
      if (path == "BottomUp") {
        for (let i = 0; i < statusBottomUp.length; i++) {
          if (_.className?.toString() == statusBottomUp[i].id) {
            setStatus(statusBottomUp[i].pgv_bottomup);
          }
        }
      }
    }
  });

  const [modalText, setModalText] = useState<string>("");
  if (status === "F") {
    var newStatus = "A";
    var dialog = "Reabrir";
    if (modalText === "") {
      setModalText("Deseja Reabrir o Planejamento da " + path + "?");
    }
  } else if (status === "A") {
    var newStatus = "F";
    var dialog = "Finalizar";
    if (modalText === "") {
      setModalText("Deseja Finalizar o Planejamento da " + path + "?");
    }
  }
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = () => {
    setVisible(true);
    if (path == "Projeções Diretoria") {
      verifyBoard();
    }
  };

  async function btup() {
    try {
      const response = await SalesPlanningService.getBotoomUpStatus(companyId);
      setStatusBottomUp(response);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    }
  }
  async function TopDownCompanyStatus() {
    try {
      const response = await TopDownCompanyService.getTableCompanyStatus(
        companyId
      );
      setStatus(response.pgv_topdown_company);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    } finally {
    }
  }
  async function TopDownBoardStatus() {
    try {
      const response = await TopDownBoardService.getTableBoardStatus(companyId);
      setStatus(response.pgv_topdown_board);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    }
  }
  async function topdownProduct() {
    try {
      const response =
        await CompanyCommercialStructureService.getAllCommercialStrucuteLevel1(
          companyId,
          Number(ids?.toString().split(",").shift())
        );
      for (let i = 0; i < response.length; i++) {
        if (response[i].level1_id == ids?.toString().split(",").pop()) {
          setExternal_name(response[i].level1_external_name);
          getTopdownProductStatus(response[i].level1_external_name);
        }
      }
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    }
  }
  async function getTopdownProductStatus(external_n: any) {
    try {
      const response = await TopDownProductService.getTableProductStatus(
        companyId,
        external_n
      );
      setStatus(response.pgv_topdown_product);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    }
  }

  async function topdownProductStatus() {
    const body = {
      status: newStatus,
      level1_id: ids?.toString(),
    };
    await TopDownProductService.putTableProductStatus(
      companyId,
      external_name,
      body
    );
  }

  useEffect(() => {
    if (path === "BottomUp") {
      btup();
    } else if (path === "Projeções Empresa") {
      TopDownCompanyStatus();
    } else if (path == "Projeções Diretoria") {
      TopDownBoardStatus();
    } else if (path == "Projeções Produtos" && ids?.toString() !== undefined) {
      topdownProduct();
    } else if (path == "Mapeamento de Carteira" && ids?.toString().length > 0) {
      WalletMappingStatus();
    } else if (path == "Mapeamento de Cliente" && ids?.toString().length > 0) {
      CustomerMappingStatus();
    } else if (path == "Previsão de Vendas" && ids?.toString().length > 0) {
      SalesForecastState();
    } else if (path == "Plano de Ação" && ids?.toString().length > 0) {
      ActionPlainStatus();
    }
  }, [path, ids]);
  const handleOk = () => {
    setModalText("Alterando o Planejamento da " + path);
    setConfirmLoading(true);
    if (path === "BottomUp") {
      ButtomUp();
    } else if (path === "Projeções Empresa") {
      TopDownCompany();
    } else if (path == "Projeções Diretoria") {
      TopDownBoard();
    } else if (path == "Projeções Produtos") {
      topdownProductStatus();
    } else if (path == "Mapeamento de Carteira") {
      PutWalletMappingStatus();
    } else if (path == "Mapeamento de Cliente") {
      PutCustomerMappingStatus();
    } else if (path == "Previsão de Vendas") {
      PutSalesForecastStatus();
    } else if (path == "Plano de Ação") {
      PutActionPlainStatus();
    }
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
      if (status === "F") {
        window.location.reload();
      } else {
        if (path !== "Projeções Diretoria") {
          navigate(-1);
        }
      }

      if (status === "F") {
        ShowMSG("success", "Reaberto");
      } else {
        if (path === "Projeções Diretoria") {
          if (statusv1 && statusv2) {
            ShowMSG("success", "Finalizado");
          }
        } else {
          ShowMSG("success", "Finalizado");
        }
      }
    }, 3000);
  };
  const handleCancel = () => {
    setVisible(false);
    setStatusv1(false);
    setStatusv2(false);
    setVerifyBoard(false);
    setMensagem1("Checando se o TopDown Empresa está fechado...");
    setMensagem2(
      "Checando se os valores do tipo de negócio estão de acordo com o planejado"
    );
  };

  async function verifyBoard() {
    try {
      const res = await TopDownCompanyService.getTableCompanyStatus(companyId);
      if (res.pgv_topdown_company === "F") {
        setStatusv1(true);
      } else {
        setStatusv1(false);
      }

      if (res.pgv_topdown_company === "F") {
        const response = await TopDownBoardService.getTableBoardVerify(
          companyId,
          Number(child)
        );
        setVerifyBoard(response.msg);
        setMensagem1("TopDown Empresa fechado");
        if (response.status === false) {
          setMensagem2(response.msg);
        } else {
          setMensagem2(
            "Valores do tipo de negócio estão de acordo com o planejado"
          );
        }
        setStatusv2(response.status);
      } else {
        setMensagem1(
          "A empresa precisa estar finalizada para finalizar a diretoria"
        );
      }
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    }
  }

  async function WalletMappingStatus() {
    try {
      const response = await WalletService.getWalletStatus(
        companyId,
        ids?.toString()
      );
      setStatus(response.pgc_wallet_customer_mapping);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    }
  }
  async function CustomerMappingStatus() {
    try {
      const response = await CostomerMappingService.getCostomerMappingStatus(
        companyId,
        ids?.toString()
      );
      setStatus(response.pgc_customer_mapping);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    }
  }
  async function SalesForecastState() {
    try {
      const response = await SalesForecastService.getBudgetStatus(
        companyId,
        +ids?.toString()
      );
      setStatus(response.pgc_budget);
    } catch (error) {
      console.log(error);
    }
  }
  async function ActionPlainStatus() {
    try {
      const response = await ActionPlanService.getStatus(
        companyId,
        +ids?.toString()
      );
      setStatus(response.pgc_action_plan);
    } catch (error) {
      console.log(error);
    }
  }
  async function PutWalletMappingStatus() {
    localStorage.setItem(
      "historico",
      "Finalizou Mapeamento_Carteira_Cliente_PGC"
    );
    try {
      const body = {
        status: newStatus,
        level4_id: Number(ids?.toString()),
      };
      await WalletService.PutWalletStatus(companyId, body);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    }
  }
  async function PutCustomerMappingStatus() {
    try {
      const body = {
        status: newStatus,
        level4_id: Number(ids?.toString()),
      };
      await CostomerMappingService.PutCostomerMappingStatus(companyId, body);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    }
  }

  async function ButtomUp() {
    localStorage.setItem(
      "historico",
      "Finalizou BottomUP_PGV: " + names?.toString()
    );
    try {
      const body = {
        status: newStatus,
        level3_id: ids?.toString(),
      };
      await BottomUpService.putBottomUpStatus(companyId, body);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    }
  }
  async function TopDownCompany() {
    localStorage.setItem("historico", "Projeções Diretoria-Regional");
    try {
      const body = {
        status: newStatus,
      };
      await TopDownCompanyService.putTableCompanyStatus(companyId, body);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    }
  }
  async function PutSalesForecastStatus() {
    try {
      const body = {
        status: newStatus,
        level4_id: +ids?.toString(),
      };
      await SalesForecastService.putBudgetStatus(companyId, body);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    }
  }

  async function PutActionPlainStatus() {
    try {
      const body = {
        status: newStatus,
        level4_id: +ids?.toString(),
      };
      console.log(body);
      await ActionPlanService.putStatus(companyId, body);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    }
  }
  async function TopDownBoard() {
    if (statusv2 === true || status === "F") {
      try {
        const body = {
          status: newStatus,
        };
        await TopDownBoardService.putTableBoardStatus(companyId, body);
        if (status === "A") {
          navigate(-1);
        }
      } catch (err) {
        ShowMSG("error", err.response.data.message);
      }
    }
  }
  return (
    <Container>
      {contextHolder}
      <Header background={background}>
        <BreadcrumbAntd separator={<Typography> {">"}</Typography>}>
          <BreadcrumbAntd.Item>
            <div style={{ display: "flex", alignItems: "center" }}>
              <LeftIcon
                onClick={() => navigate(-1)}
                src={leftIcon}
                style={{ marginRight: "5px" }}
              />
              <Typography> Voltar</Typography>
            </div>
          </BreadcrumbAntd.Item>
          <Typography style={{ display: "flex", alignItems: "center" }}>
            {ids?.map((item, index) => (
              //! retirar o id impresso na tela
              <BreadcrumbAntd.Item key={index}>
                {path !== "Projeções Produtos" &&
                path !== "BottomUp" &&
                path !== "Mapeamento de Carteira" &&
                path !== "Mapeamento de Cliente" &&
                path !== "Previsão de Vendas" &&
                path !== "Plano de Ação" &&
                path !== "Tranferir Clientes"
                  ? item
                  : path === "Mapeamento de Carteira" ||
                    path === "Mapeamento de Cliente" ||
                    path === "Plano de Ação" ||
                    path === "Tranferir Clientes"
                  ? child
                  : ""}
              </BreadcrumbAntd.Item>
            ))}
          </Typography>
        </BreadcrumbAntd>
        <Actions>
          {actions?.map((item, index) => (
            <>
              {status !== "" ? (
                <Button
                  key={index}
                  disabled={disabled}
                  onClick={showModal}
                  {...item}
                />
              ) : (
                <></>
              )}
            </>
          ))}
        </Actions>
        <Modal
          title={status === "F" ? "Reabrir" : "Finalizar"}
          open={visible}
          okText={"Sim"}
          cancelText={"Não"}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          okButtonProps={{
            disabled:
              path === "Projeções Diretoria"
                ? (statusv1 && statusv2) || status === "F"
                  ? false
                  : true
                : false,
          }}
          onCancel={handleCancel}
        >
          {path === "Projeções Diretoria" && status === "A" ? (
            <>
              <p>
                {statusv1 ? (
                  "✅" + mensagem1
                ) : mensagem1 ===
                  "Checando se o TopDown Empresa está fechado..." ? (
                  <>
                    <Spin /> {mensagem1}
                  </>
                ) : (
                  "❌" + mensagem1
                )}
              </p>
              {statusv1 ? (
                <p>
                  {statusv2 ? (
                    "✅" + mensagem2
                  ) : mensagem2 ===
                    "Checando se os valores do tipo de negócio estão de acordo com o planejado" ? (
                    <>
                      <Spin /> {mensagem2}
                    </>
                  ) : (
                    "❌" + mensagem2
                  )}
                </p>
              ) : (
                <> </>
              )}
            </>
          ) : (
            <></>
          )}
          <p>{modalText}</p>
        </Modal>
      </Header>
      <Content>{children}</Content>
    </Container>
  );
}
